.App {
  text-align: center;
  background: linear-gradient(to bottom, #3a4755, #202a36);
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 200px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

h1 {
  font-size: 5vmin;
  color: #ffffff;
  margin: -30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  transform: translateX(-5px);
}

.animate-text {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

.first {
  animation-delay: 0.7s;
}

.second {
  animation-delay: 1.2s;
}

.third {
  animation-delay: 1.7s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



